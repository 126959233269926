import React from 'react'
import styles from './table-of-contents.scss'
import { IoIosExpand, IoIosContract } from "react-icons/io";

class TableOfContents extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false
    }
    this.toggleContent = this.toggleContent.bind(this)
    this.goToElement = this.goToElement.bind(this)
  }

  /**
   * Shows/Hide list of contents
   * @return {void}
   */
  toggleContent(e) {
    this.setState({
      open: !this.state.open
    })
  }

  /**
   * Smoothly scrolls down to element in page
   * @param  {Event} e
   * @return {void}
   */
  goToElement(e) {
    const { target } = e
    const id = (target.getAttribute('href') || '').replace('#', '')
    const targetElement = document.getElementById(id)
    const targetElementSticky = document.getElementById('sticky-bar')
    if (targetElement) {
      e.preventDefault()
      const pos = targetElement.getBoundingClientRect()
      const posStycky = targetElementSticky.getBoundingClientRect()
      const targetTop = pos.top + window.scrollY - posStycky.height
      window.scroll({top: targetTop, left: 0, behavior: 'smooth' });
    }
  }

  /**
   * Renders ToC tree recursively
   * @param  {Array} nodes
   * @return {Node}
   */
  renderTree(nodes) {
    if (nodes) {
      return (
        <ol>
          { nodes.map((node, index) => {
            return (
              <li key={index}>
                <a href={`#${node.id}`} onClick={this.goToElement}>{node.label}</a>
                {
                  node.children && node.children.length ? this.renderTree(node.children) : ''
                }
              </li>
            )
          }) }
        </ol>
      )
    }
  }

  render() {
    if (this.props.content) {

    }
    return (
      <div className={`toc ${this.props.className ? this.props.className : ''}`}>
        <h2>Table of Contents</h2>
        <button className="btn btn-outline-dark btn-square toc-toggler" onClick={this.toggleContent}>
          { this.state.open ? (
            <IoIosContract />
          ) : (
            <IoIosExpand />
          ) }
        </button>
        <div className="toc-content" style={{ display: this.state.open ? 'block' : 'none' }}>
          { this.props.content ? this.renderTree(this.props.content) : ''}
        </div>
      </div>
    )
  }
}

export default TableOfContents
